import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import {connect} from 'react-redux';
import {ListView} from '../../../components/ListView';
import {MonumentCard} from "./MonumentCard";
import {AccessoryCard} from "../../configurator/accessory/AccessoryCard";
import * as apiService from "../../../services/api.service";
import {bindActionCreators} from "redux";
import * as uiActions from "../../../actions/uiActions";
import * as updateConfigurationActions from "../../../actions/updateConfigurationActions";
import * as configurationActions from "../../../actions/configurationActions";
import {LazyImage} from "../../../components/LazyImage";
import {displaySimpleAccesory} from '../../../services/image.fct.service';
import {imgs} from "../../../services/images.service";
import * as _ from "lodash";

export class AccessoryListComponent extends Component {
  constructor(props){
    super(props)
    this.state={kind:"Vase", Defaultgranite :"NF"}
  }
  async componentDidMount (){
    if (!this.props.configurator.accessories){
      const accessories = await apiService.get('/api/catalog/accessories');
      this.props.configActions.storeAccessories(accessories);
    }  
  }
  clickOnAccessory(accessory, granite) {
        this.props.onSelect(accessory, granite)
  }
  showItem = (accessory,index) =>{
    const granites = accessory.granites
    const granite = granites.map((granite,index) => index === 0 ? granite.reference : '' )
    //const isMonumentMadeInFrance = false
    const theClass = "AccessoryCard"
    let thumbnailGranite = this.state.Defaultgranite

    //Granit choosing rules for the accessory:
    if (_.find(granites,{id: accessory.granite})){
      const thumbGranite = granites.filter(gr => accessory.granite === gr.id)
      thumbnailGranite = thumbGranite[0].reference
    }else if (_.find(granites,{reference: accessory.defaultgranit})){
      thumbnailGranite = accessory.defaultgranit
    }else{
      thumbnailGranite = granites.length>0 && granites[0].reference
    }
    
    return <div className="ListViewItem" key={index}>
      <div className="ListViewItemContainer">
        <div key={index} className={theClass} onClick={() => this.clickOnAccessory(accessory,thumbnailGranite) }>
          <LazyImage src={displaySimpleAccesory(accessory.reference,thumbnailGranite)} lazy={false} customStyle={true}/>
          <div className="Infos">
            <h1 className="Description">
              {accessory.description}
            </h1>
            <p className="Reference">{accessory.reference}</p>
          </div>
        </div>
      </div>
    </div>
  }

  render() {
    const allAccessories = this.props.accessories;
    const vases = allAccessories.filter(accessory=> accessory.kind==='Vase')
    const jardin = allAccessories.filter(accessory=> accessory.kind === 'Jardinière')
    const divers = allAccessories.filter(accessory => accessory.kind === 'Divers')
    const {kind} = this.state
    if (kind == 'Vase' && vases.length <= 0) {
      if (jardin.length > 0) {
        this.setState({kind: "Jardinière"})
      } else if (divers.length > 0) {
        this.setState({kind: "Divers"})
      }
    }
    
    return (
      <div className="AccessoryContainer">
        <div className="FilterAccessories">
          {vases &&vases.length > 0 && <div className={kind==='Vase' ? 'FilterOption active' : 'FilterOption'} onClick={() => this.setState({kind: "Vase"})}><i className="icon icon-accessory-vase"/> Vases</div>}
          {jardin && jardin.length > 0 && <div className={kind==='Jardinière' ? 'FilterOption active' : 'FilterOption'} onClick={() => this.setState({kind: "Jardinière"})} ><i className="icon icon-accessory-box"/> Jardinières</div>}
          {divers && divers.length > 0 && <div className={kind==='Divers' ? 'FilterOption active' : 'FilterOption'} onClick={() => this.setState({kind: "Divers"})} ><i className="icon icon-accessories-misc"/> Divers</div>}
        </div>
        <div className="ListView">
          <div className="ListViewContainer">
            <div className="ListView">
              <div className="container">{allAccessories && allAccessories.map((accessory,index) => {
                const granitesLength = accessory.granites ? accessory.granites.length : 0
                if (accessory.kind === kind && granitesLength>0 ){
                  return this.showItem(accessory,index)
                }
                })}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AccessoryListComponent.propTypes = {
  onSelect: PropTypes.func.isRequired,
  roles: PropTypes.array,
  collection: PropTypes.string,
};


export const AccessoryList = connect(
  (state) => ({
    configurator: state.configurator,
    /*configuration: state.configurator.current.configuration,*/
    allAccessories: state.configurator.accessories,
    /*allGranites: state.configurator.current.options.granites,*/
    roles: get(state, 'user.roles'),
    collection: get(state, 'routing.locationBeforeTransitions.query.collection'),
  }),
      (dispatch) => ({
        actions: bindActionCreators(updateConfigurationActions, dispatch),
        configActions: bindActionCreators(configurationActions, dispatch),
      })
)(AccessoryListComponent);