import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as updateConfigurationActions from '../../../actions/updateConfigurationActions';
import {ModalButton} from '../../../components/ModalButton';
import {GranitePicker} from '../components/GranitePicker';
import {isQuote} from '../../../services/restrictedConfiguration';
import { isConfigRequested } from "../../../services/configurationWarningChecks";
import { TranslationContext } from "../../../context/TranslationContext";
import {displayGranitConfigImage} from '../../../services/image.fct.service';


/**
 * ModalButton permettant de choisir le granite pour la semelle
 */
class FrameGranitePickerComponent extends Component {
  static contextType = TranslationContext;
  static propTypes = {
    monument: PropTypes.shape({
      graniteMain: PropTypes.string.isRequired,
    }).isRequired,
    frame: PropTypes.object,

  };

  modal;

  onSelectGranite = (reference) => {
    if(this.props.monument.kind === 'SEM'){
      this.props.actions.updateGraniteMonument(reference);
    }
    this.props.actions.updateGraniteFrame(reference);
    this.modal.close();
  };

  // Génére l'url de l'image de prévisualisation 3D de la semelle
  // dans un granit suggéré.
  makePreviewUrl = (graniteRef)=> {
    const {monument, frame, configuration} = this.props;
    // return imgs.configuration.monument.iso.thumbnail({
    //   monument,
    //   frame: Object.assign({}, frame, {granite: graniteRef})
    // }, {
    //   excludeDefaultPattern: true
    // })
    return displayGranitConfigImage(configuration,
      monument.graniteMain,
      false,
      monument.graniteSecondary,
      "thumbnail",
      false,
      0,
      0,
      graniteRef,
      0,
      0)
  };

  render() {
    const t = this.context;
    const {frame} = this.props;
    const hasFrame = frame && frame.mode;
    const disabled = !hasFrame || isQuote(this.props.configuration) || isConfigRequested();

    const buttonContent = t("config3d_granit_button_frame") || "Semelle";
    return (
      <div className={"btn-wrapper"}>
        <ModalButton ref={(_modal) => this.modal = _modal}
                    buttonContent={buttonContent}
                    buttonIcon="frame-icon"
                    className="FrameGranitePickerPopupModal buttonModal background-page"
                    buttonClassName={`btn-config-secondary text-dark config-button-background ${!disabled && 'config-button-hover'}`}
                    overlayClassName="FrameGranitePickerPopupModalOverlay"
                    disabled={disabled}
                    toggleSteps={this.props.toggleSteps}
                    launchIntro={this.props.launchIntro}
                    madalName={this.props.madalName}
                    >

          <GranitePicker
            currentGraniteRef={frame && frame.granite}
            onPickGraniteRef={this.onSelectGranite}
            suggestionImageURL={this.makePreviewUrl}
            title={ t("config3d_granit_modal_frame_title") || "Choisissez un granit à appliquer sur la semelle" } />
        </ModalButton>
      </div>
    )
  }
}

export const FrameGranitePicker = connect((state) => ({
    configuration: state.configurator.current.configuration,
    monument: state.configurator.current.configuration.monument,
    frame: state.configurator.current.configuration.frame,
  }),
  (dispatch) => ({
    actions: bindActionCreators(updateConfigurationActions, dispatch)
  })
)(FrameGranitePickerComponent);
