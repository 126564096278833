import React, { Component } from "react";
import Select from 'react-select';
import { connect } from "react-redux";
import { getDesignTypeConfiguration } from '../../services/utils.service';
import { gtmFamily } from '../../services/gtmFamily';
import { FiltersBurial } from '../../constants';

class FfilterCatalogComponent extends Component {
    state = {
      selectedIndex:1,
      searchInput: "",
    };

  componentDidMount() {
        this.onClickIcon(this.props.fIndex);
  }
  componentDidUpdate(prevProps, prevState){
    // Cas tordus de rechargement de page on vérifie qu'on recharge bien le filtre
    if (!prevProps.catalog.search || (prevProps.ui.initChoiceMade !== this.props.ui.initChoiceMade)){
      if (this.state.selectedIndex !== this.props.fIndex) {
        this.onClickIcon(this.state.selectedIndex);
      }    
    } else {
      // this.props.fIndex !== this.state.selectedIndex / this.props.fIndex !== prevProps.fIndex
    if (this.props.fIndex && (this.props.fIndex !== this.state.selectedIndex)){
      this.onClickIcon(this.props.fIndex);
      }
    }
    
    // GTM
    if (prevProps.catalog.search != null && prevState.selectedIndex != this.state.selectedIndex) {
      let self = this;
      gtmFamily().listOfMonuments(
        "filter.apply",
        self.props.catalog.visibleMonuments,
        { 
          mainFilter: "Inhumation" , 
          secondFilter: FiltersBurial[self.state.selectedIndex].label 
        }
      )
    }
    // end GTM
  }

  onClickIcon = (index) => {
    // if (this.state.searchInput.replace(/\s/g, '') !== "") {
    //   // Cancel search on tab selection
    //   this.setState({searchInput: ''});
    // }
    if(index!==-1 && index !== undefined){
        let filter = {};
        filter["searchField"] = ""
        if (this.state.searchInput && this.state.searchInput !== "") {
          filter["searchField"] = this.state.searchInput
        }
        FiltersBurial[index].category ? filter["category"] = FiltersBurial[index].filter
                                       :filter[FiltersBurial[index].filter]=true;
        this.props.onFilter(filter);
        this.setState({selectedIndex:index})
        this.props.handleFIndex(index);
    }
  };

  getClassName = (index) =>{
      let className = "FilterButton ";
      className += index === this.state.selectedIndex ? " active":"";
      return className;
  };

  // getSelects() {
  //   let tab = [];
  //    FiltersBurial.forEach(filter=>tab.push({label:`${filter.label}`,value:filter.label}));
  //    return tab;
  // };

  // changeSelect = (filter) => {
  //   if (filter.value){
  //     let index = FiltersBurial.findIndex(item=>item.label ===filter.value);
  //     this.onClickIcon(index);
  //   }
  // };

  // getActiveSelect(){
  //   const {selectedIndex} = this.state;
  //   if (selectedIndex){return{label:`Filtrer: ${FiltersBurial[selectedIndex].label}`,value:FiltersBurial[selectedIndex].label} }
  //   else return{label:`Filtrer: ${FiltersBurial[0].label}`,value:FiltersBurial[0].label}
  // };

  handleSearchInput = ({target}) => {
    if (target.value.replace(/\s/g, '') === "") {
      // restore old selected tab if search cancelled
      this.onClickIcon(this.state.selectedIndex);
    }
    // this.props.search(target.value.toUpperCase())
    let filter = {};
    filter["searchField"] = target.value.toUpperCase()
    FiltersBurial[this.state.selectedIndex].category ? filter["category"] = FiltersBurial[this.state.selectedIndex].filter
                                    :filter[FiltersBurial[this.state.selectedIndex].filter]=true;

    this.setState({searchInput : target.value.toUpperCase()}, () => this.props.onFilter(filter));
  };

  render() {
    const { searchInput } = this.state;
    // const selectedOption = this.getActiveSelect();
    const designType = getDesignTypeConfiguration ()
    return (
        <div className={`FfilterMain ${designType==='A' && 'fFilterA'}`}>
            <div className="FfilterCatalog FfilterMonuments">
                <div className="search-filter-input-box">
                  <input
                    className="search-filter-input"
                    placeholder="Chercher un monument"
                    type="text"
                    value={searchInput}
                    onChange={this.handleSearchInput}
                  />
                  <i className="icon material-icons">search</i>
                </div>
                <div className="filterButtons">
                  {FiltersBurial.map((elt,index) =>
                      <button key={index} className={this.getClassName(index)} onClick={() => this.onClickIcon(index)}>
                          {elt.label}
                      </button>
                  )}
                </div>
            </div>
            
            {/* <div className="FfilterSelect">
                <Select className="dropdownList"  value={selectedOption} options={this.getSelects()}
                            onChange={(option)=>this.changeSelect(option)}  isSearchable={false}/>
            </div> */}
        </div>
    );
  };
}
export const FfilterCatalog = connect(
  state => ({
    catalog: state.catalog,
    ui:state.ui,
  })
)(FfilterCatalogComponent);
