import React, { useContext, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as updateConfigurationActions from "../../../actions/updateConfigurationActions";
import * as _ from "lodash";
import cn from "classnames";
import InputRange from "../../../components/InputRange";
import { getEngravingAvailableInfo } from "../../../services/generateEngravings.service";
import { pickOne, onSelectKind, onSelectColor, onSelectFamily, onChangeSize } from "../../../services/commonEngraving.service";
import { TranslationContext } from "../../../context/TranslationContext";
import { Modal } from '../../../components/Modal';

const EngravingParamsComponent = (props) => {
  const t = useContext(TranslationContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const { engravings, engravingTypes, onClose, adminConfig, user } = props;

  const kind = pickOne("font.kind", props);
  const color = pickOne("font.color", props);
  const family = pickOne("font.family", props);
  const size = pickOne("font.size", props);
  const lineHeight = pickOne("font.lineHeight", props);
  const listEngravings = getEngravingAvailableInfo(user, engravingTypes, kind, color, family, adminConfig.engravingsToHide);

  const { minSize, maxSize } = useMemo(() => {
    const filteredEngravings = engravingTypes.filter(engraving =>
      (engraving.type.indexOf("Lettres " + listEngravings.selectedType) !== -1) && (engraving.fontFamily === listEngravings.selectedFont.id) && (engraving.color === listEngravings.selectedColor.id)
    );

    const fontSizes = filteredEngravings.length ? filteredEngravings.map(engraving => parseInt(engraving.fontSize)) : [];
    const minSize = fontSizes.length > 0 ? Math.min(...fontSizes) : 15;
    const maxSize = fontSizes.length > 0 ? Math.max(...fontSizes) : 60;

    return { minSize, maxSize };
  }, [engravingTypes, listEngravings.selectedType, listEngravings.selectedFont.id, listEngravings.selectedColor.id]);

  const getBalckEngravingAlert = async () => {
    const errorMessage = t("config3d_engraving_warning_modal") || "Attention, le choix d'une gravure noire peut, sur certains granits veinés en noire, conduire à un manque de visibilité des caractères.";
    setModalVisible(true);
    setModalMessage(errorMessage);
  };

  return (
    <section
      className={cn("EngravingParameters background-page", {
        active: engravings && engravings.length
      })}
    >
      <div className="Headline">
        <h5>
          {t("config3d_patternandengravings_modal_engravings_title") || "Editer la gravure"}
        </h5>
        <div className="Close" href="#" onClick={onClose}>
          <span className={`close-icon icon icon-medium`}></span>
        </div>
      </div>

      <div className="Row">
        <label className="Kind">
          <span className="Label">
            {t("config3d_patternandengravings_modal_engravings_type_title") || "Type de gravure"}
          </span>
          <div className="Field">
            <select value={kind || ""} onChange={e => onSelectKind(e, props)}>
              {!kind && <option value="">----</option>}
              {_.map(listEngravings.types, (type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
        </label>
      </div>

      <div className="Row" style={{ marginTop: "-0.313rem" }}>
        <label className="Color">
          <span className="Label">
            {t("config3d_patternandengravings_modal_engravings_color_title") || "Couleur"}
          </span>
          <div className="Field ColorPicker">
            {listEngravings.colors.map((item) => (
              <div
                key={item.id}
                onClick={async () => {
                  onSelectColor(item.id, props);
                  item.id === "#000000" && await getBalckEngravingAlert();
                }}
                className={cn("Color", { active: listEngravings.selectedColor && listEngravings.selectedColor.id === item.id })}
                style={{ backgroundColor: item.id }}
              >{item.label}</div>
            ))}
          </div>
        </label>
      </div>

      <div className="Row">
        <label className="FontSize">
          <span className="Label">
            {t("config3d_patternandengravings_modal_engravings_size") || "Taille des caractères (mm)"}
          </span>
          <div className="Field">
            <InputRange
              value={size}
              min={minSize}
              max={maxSize}
              step={1}
              onValueChange={(value) => onChangeSize(value, props)}
            />
          </div>
        </label>
      </div>

      <div className="Row" style={{ marginTop: "-0.313rem" }}>
        <label className="Font">
          <span className="Label">
            {t("config3d_patternandengravings_modal_engravings_font_title") || "Police"}
          </span>
          <div className="Field FamilyPicker">
            {listEngravings.fonts.map((item) => (
              <div
                key={item.id}
                className={cn("Family", { active: listEngravings.selectedFont.id === item.id })}
                onClick={() => onSelectFamily(item.id, props)}
              >
                <label style={{ fontFamily: item.data.family }} htmlFor={`fontCheckbox_${item.id}`}>{item.id}</label>
                <div className="round">
                  <input
                    id={`fontCheckbox_${item.id}`}
                    type="checkbox"
                    checked={listEngravings.selectedFont.id === item.id}
                  />
                  <label htmlFor="checkbox"></label>
                </div>
              </div>
            ))}
          </div>
        </label>
      </div>

      <Modal
        isOpen={modalVisible}
        onRequestClose={() => setModalVisible(false)}
        className='engraving-alert'
      >
        <p>{modalMessage}</p>
        <button
          className="text-cta secondary1 custom-secondary-button button-primary button-small text-cta"
          onClick={() => setModalVisible(false)}
        >
          OK
        </button>
      </Modal>
    </section>
  );
};

EngravingParamsComponent.propTypes = {
  engravings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  engravingTypes: PropTypes.any.isRequired,
  engravingsId: PropTypes.arrayOf(PropTypes.string).isRequired,
  calculateEngravingsLines: PropTypes.func.isRequired,
  onClose: PropTypes.func
};

export const EngravingParams = connect(
  (state, ownProps) => ({
    engravings: state.configurator.current.configuration.engravings.filter(
      engraving => ownProps.engravingsId.indexOf(engraving.id) >= 0
    ),
    engravingTypes: state.configurator.current.options.engravingTypes || {},
    adminConfig: state.adminConfig.config,
    user:state.user
  }),
  dispatch => ({
    actions: bindActionCreators(updateConfigurationActions, dispatch)
  })
)(EngravingParamsComponent);
