import {getReactAppExtranet} from './utils.service';

const GRANT_URL = getReactAppExtranet("/api/v4/token");
const CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID;

// The latest OAuth promise. Passes the response with the `access_token` and
// `refresh_token`.
let currentGrant;

// An OAuth refresh token grant promise. Used to avoid parallel requests to
// refresh a token that would fail (a refresh token can only be used once).
let refreshGrant = null;

function getTokens(body) {
  refreshGrant = null;
  return body;
}

// Generic grant.
function grant(grant_type, params) {
  params.client_id = CLIENT_ID;
  params.grant_type = grant_type;

  const body = Object
    .keys(params)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join("&");

  const options = {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: body,
    credentials: "omit",
  };

  return currentGrant = fetch(GRANT_URL, options)
    .then(response => {
      if (response.status >= 400) {
        return response.json()
          .then(body => { throw new Error(`Authentication failure : ${body["error"]}`) });
      } else {
        return response.json().then(getTokens);
      }
    });
}

// Exchange `code` for `access_token` + `refresh_token` pair of tokens.
function authorizationCodeGrant(code) {
  return currentGrant = grant("authorization_code", { code: code });
}

// Exchange a `refresh_token` for a new set of tokens.
function refreshTokenGrant(refreshToken) {
  if (refreshGrant) return refreshGrant;
  return currentGrant = refreshGrant = grant("refresh_token", { refresh_token: refreshToken });
}

export default {
  initiated: () => !!currentGrant,
  authorizationCodeGrant: authorizationCodeGrant,
  refreshTokenGrant: refreshTokenGrant,
  then: (...args) => currentGrant.then(...args),
};
