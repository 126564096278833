import React, {Component} from 'react';
import * as _ from 'lodash';
import {FabricFooter} from './FabricFooter';
import {FabricActionBar} from './FabricActionBar';
import {fabric} from 'fabric';

import {shouldFocus} from '../../../services/goToAndFocusItem.service';
import { isQuote } from '../../../services/restrictedConfiguration';

import { TranslationContext } from "../../../context/TranslationContext";

export const typePattern = 'P';
export const typeEngraving = 'E';
export const typeAccessory = 'A';

export const defaultOptions = {
  borderColor: "#0095E8", // Couleur de la bordure quand l'objet est sélectionné
  backgroundColor: 'rgba(0, 149, 232, 0)', // Background color lorsque l'object est selectionné
  borderScaleFactor: 1, // Semble controler l'épaisseur de la bordure. 1 est la valeur par défaut. Plus on s'approche de 0, plus c'est épais.
  borderOpacityWhenMoving: 1, // Opacité de la bordure et des coins lorsque l'objet est en train d'être déplacé
  cornerColor: "#0095E8", // Couleur des carrés dans chaque coin
  cornerSize: 12, // Taille des carrés
  transparentCorners: false, // Par défaut, les carrés dans chaque coin sont transparents avec une fine bordure.
};

const guideExtension = 25;
const gridInMm = 1;
const defaultSnapDistanceInPx = 7;

const keyCodes = {
  backspace: 8,
  del: 46,
  enter: 13,
  arrowLeft: 37,
  arrowTop: 38,
  arrowRight: 39,
  arrowBottom: 40,
};

const outsideFaceColor = 'rgba(255, 0, 0, 0.15)';
const outsideFaceBoxType = 'outsideFaceBox';

const maxEngravingLength = 48;

/**
 * Composant permettant de manipuler les motifs/gravures/accessoires
 */
export class Fabric extends Component {
  static contextType = TranslationContext;

  state = {
    selectedObjects: [],
    actionsPositionX: -9999, // En 2 paramètres pour éviter de recalculer pour rien
    actionsPositionY: -9999,
    movingOutside: false,
    editEngrav: false,
  };
  backgroundImage = null; // Objet Fabric de l'image de fond
  canvasParent = null; // Element parent du canvas définissant la taille disponible pour le canvas
  canvasContainer = null; // Container permettant de centrer le canvas
  canvas = null;

  vGuides = []; // Guides verticaux
  hGuides = []; // Guides horizontaux
  objectGuides = null; // Guides de l'objet courant
  faceBoundingRect = null; // Rectangle de la face

  updateObjectsOnKeyUp = null;

  componentDidMount() {
    this.canvas = new fabric.Canvas('canvas', { stateful: true});
    this.canvas.selection = false;
    this.canvas.upperCanvasEl.id = "upperCanvas";
    const w = window.innerWidth;
    const h = window.innerHeight;
    this.canvas.height = h;
    this.canvas.width = w;
    this.canvas.enableRetinaScaling = false;
    this.canvas.on('object:scaling', this.onObjectScaling);
    this.canvas.on('object:moving', this.onObjectMoving);
    this.canvas.on('object:rotating', this.onObjectRotating);
    this.canvas.on('text:changed', this.onTextChanged);
    this.canvas.on('text:editing:exited', this.onTextEditingExited);
    this.canvas.on('selection:created', this.onObjectSelectionCreated);
    this.canvas.on('selection:updated', this.onObjectSelectionCreated);
    this.canvas.on('selection:cleared', this.onObjectSelectionCleared);
    this.canvas.on('object:modified', this.onObjectChange);
    this.canvas.on('object:moving', this.restrictObjectMovement);
    this.initCanvas(this.props, () => {
      this.selectItemsFromHash();
      this.props.onReady && this.props.onReady();
    });
    window.addEventListener('resize', this.updateCanvasSize);
  }
   componentDidUpdate(prevProps){
    if (this.props.undoCounter && this.props.undoCounter !== prevProps.undoCounter){
      // en cas de "Defaire Refaire" on reconstruit le canvas si l'objet a été déplacé
      this.initCanvas(this.props, () => {
        this.selectItemsFromHash();
        this.props.onReady && this.props.onReady();
      });
    }
  } 

  // Permet de déclencher la sélection d'un item depuis l'onglet finalisation
  selectItemsFromHash = () => this.canvas.getObjects().forEach((item) => {
    if (shouldFocus(item.id)) {
      this.activateObjectById(item.id)
    }
  });

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateCanvasSize);
    this.canvas.clear();
    this.canvas = null;
  }

  componentWillReceiveProps(nextProps) {
    // Si ce n'est pas le background qui a changé, on regarde si c'est l'un des items
    if (!this.hasBackgroundChanged(nextProps)) {
      // On vérifie dans chaque list d'items lesquels ont été ajoutés/supprimés/modifiés
      const check = _.map(this.itemsProperties, ({add, update, remove}, type) => {
        const prev = this.getItemsFromProps(this.props, type);
        const next = this.getItemsFromProps(nextProps, type);

        const added = _.differenceBy(next, prev, 'id');
        if (added.length) {
          add(nextProps, added);
        }

        const removed = _.differenceBy(prev, next, 'id');
        if (removed.length) {
          (remove ? remove : this.removeItemsFromCanvas)(nextProps, removed);
        }

        const updated = _.intersectionBy(next, prev, 'id');
        _.forEach(updated, item => {
          const object = _.find(this.canvas.getObjects(), {id: item.id});
          if (object) {
            update(nextProps, item, object,"update");
          }
        });

        return {
          added,
          removed,
          updated,
        };
      });

      const removed = _.chain(check).map('removed').flatten().map('id').value();
      const added = _.chain(check).map('added').flatten().map('id').value();

      // S'il y a eu des suppressions, on retire de la sélection ces objets
      if (this.state.selectedObjects.length && removed.length) {
        const newSelection = _.reject(this.state.selectedObjects, obj => {
          return removed.indexOf(obj.id) >= 0;
        });
        if (newSelection.length !== this.state.selectedObjects.length) {
          if (!newSelection.length) {
            this.unselectAll();
          }
          this.updateSelection(newSelection);
        }
      }

      // On sélectionne le dernier élément ajouté
      if (added.length) {
        this.activateObjectById(_.last(added))
      }

      // Quand on effectue des modifications depuis l'extérieur du canvas comme la taille des polices,
      // il faut repositionner la barre d'action
      this.updateActionBarPosition();
    }
  }

  activateObjectById(id) {
    // Dans le cas où l'on ajoute une gravure suite à un saut de ligne, on ne veut pas sélectionner le texte
    const activateOnNewEngravingLine = this.state.selectedObjects.length === 1
      && this.state.selectedObjects[0].type === typeEngraving
      && this.state.selectedObjects[0].isEditing;

    const object = _.find(this.canvas.getObjects(), {id});
    this.unselectAll();
    this.canvas.setActiveObject(object);
    if (object.type === typeEngraving) {
      // Si c'est une gravure, on passe en mode édition
      this.enterEditingForEngraving(object, !activateOnNewEngravingLine);
    } else {
      // Sinon on focus le container afin d'avoir directement le support du clavier
      this.focusContainer(this.canvasContainer);
    }
  }

  enterEditingForEngraving(object, select = true) {
    setTimeout(() => {
      object.enterEditing();
      if (select) {
        setTimeout(() => {
          object.selectAll();
        })
      }
    });
  }

  initCanvas = (props, callback) => {
    // On reset le canvas
    this.canvas.clear();

    // Puis on ajoute le background et ensuite les objets
    this.updateBackgroundImage(props, () => {
      this.addAllItemsToCanvas(props);
      callback && callback();
    });

  };

  updateBackgroundImage = (props, cb) => {
    this.backgroundImage = null;
    this.canvas.setBackgroundImage(0, this.canvas.renderAll.bind(this.canvas));

    const imgSrc = this.getBackgroundImage(props);

    
    fabric.Image.fromURL(imgSrc, image => {
      this.backgroundImage = image;
      const widthCanvas = image.getScaledWidth()>0 ? image.getScaledWidth() : 1800;
      const heightCanvas = image.getScaledHeight()>0 ? image.getScaledHeight() : 1800;
      if (!this.canvas) return; 
      this.canvas.setDimensions({
        width: widthCanvas,
        height: heightCanvas
      });
      this.canvas.setBackgroundImage(image, this.canvas.renderAll.bind(this.canvas));
      this.doUpdateCanvasSize();
      cb && cb();
    });
  };

  // On conserve cette fonction pour permettre de retirer le listener
  updateCanvasSize = _.throttle(() => this.doUpdateCanvasSize(), 50);

  // Redimensionne le canvas en fonction de la taille de l'écran
  doUpdateCanvasSize = () => {
    
    if (!this.canvasParent || !this.backgroundImage) return;
 
    const maxWidth = this.canvasParent.offsetWidth;
    const maxHeight = this.canvasParent.offsetHeight;
    let canvasZoomFactor = Math.min(maxWidth / this.canvas.width, maxHeight / this.canvas.height) || 1;
    // this.backgroundImage.set({
    //   scaleX: canvasZoomFactor,
    //   scaleY: canvasZoomFactor,
    // });
    this.canvasContainer.style.width = (this.canvas.width * canvasZoomFactor) + 'px';
    this.canvas.setZoom(canvasZoomFactor);
    this.checkIfOutsideFace();
    this.updateActionBarPosition();

    const face = this.props.face;
    if (face) {
      this.faceBoundingRect = {
        height: face.zone.height * this.canvas.height,
        left: (face.zone.x + face.zone.width / 2.0) * this.canvas.width,
        top: (face.zone.y + face.zone.height / 2.0) * this.canvas.height,
        width: face.zone.width * this.canvas.width,
      };
    } else {
      // Si pas de face associée à l'image de fond (cas des accessoires)
      this.faceBoundingRect = {
        height: this.canvas.height,
        left: this.canvas.width / 2,
        top: this.canvas.height / 2,
        width: this.canvas.width,
      };
    }
    // On arrondi à l'entier pair au dessus
    this.faceBoundingRect.left += this.faceBoundingRect.left % 2;
    this.faceBoundingRect.top += this.faceBoundingRect.top % 2;

    // englobing rectangle
    const outsideFaceBoxes = _.filter(this.canvas.getObjects(), {type: outsideFaceBoxType});
    if (!outsideFaceBoxes.length) {
      for (let i = 0; i < 4; i++) {
        const box = new fabric.Rect({
          type: outsideFaceBoxType,
          fill: outsideFaceColor,
          selectable: false,
          visible: this.state.movingOutside,
        });
        outsideFaceBoxes.push(box);
        this.canvas.add(box);
      }
    }

    const leftWidth = this.faceBoundingRect.left - this.faceBoundingRect.width / 2;

    // Left
    outsideFaceBoxes[0].set({
      top: 0,
      left: -1,
      width: leftWidth + 1,
      height: this.canvas.height,
    });
    // Right
    outsideFaceBoxes[1].set({
      top: 0,
      left: this.faceBoundingRect.left + this.faceBoundingRect.width / 2,
      width: leftWidth,
      height: this.canvas.height,
    });
    // Top
    outsideFaceBoxes[2].set({
      top: 0,
      left: leftWidth,
      width: this.canvas.width - (2 * leftWidth),
      height: this.faceBoundingRect.top - this.faceBoundingRect.height / 2,
    });
    // Bottom
    outsideFaceBoxes[3].set({
      top: this.faceBoundingRect.top + this.faceBoundingRect.height / 2,
      left: leftWidth,
      width: this.canvas.width - (2 * leftWidth),
      height: this.faceBoundingRect.top - this.faceBoundingRect.height / 2,
    });
  };

  /**
   * Manipulation des objets au clavier
   *
   * On conserve cette fonction pour permettre de retirer le listener
   * On désactive l'execution en trailing parce que celle ci aura comme argument un evenement qui
   * n'existe plus.
   *
   * @type {Function}
   */
  onKeyDown = _.throttle((event) => this.doOnKeyDown(event), 50, {leading: true, trailing: false});

  doOnKeyDown = (event) => {
    const {keyCode, altKey, ctrlKey, metaKey, shiftKey} = event;

    switch (keyCode) {
      case keyCodes.backspace:
      case keyCodes.del:
        if (this.state.selectedObjects.length) {
          event.preventDefault();
          this.onRemoveObjects();
        }
        break;
      case keyCodes.enter:
        if (this.state.selectedObjects.length === 1
          && this.state.selectedObjects[0].type === typeEngraving) {
          event.preventDefault();
          this.enterEditingForEngraving(this.state.selectedObjects[0]);
        }
        break;
      case keyCodes.arrowLeft:
      case keyCodes.arrowTop:
      case keyCodes.arrowRight:
      case keyCodes.arrowBottom:
        if (!this.state.selectedObjects.length) {
          // Aucun élément sélectionné
          break;
        }

        if (altKey || metaKey || (ctrlKey && this.canvas.getActiveObjects())) {
          // Aucune combinaison avec alt ou pomme/windows n'est supportée ainsi que la rotation de groupe
          break;
        }

        const object = this.canvas.getActiveObject() || this.canvas.getActiveObjects();

        if (ctrlKey) {
          // Rotation de l'objet sélectionné

          let delta = shiftKey ? 20 : 2;
          if (keyCode === keyCodes.arrowLeft || keyCode === keyCodes.arrowTop) delta = -delta;
          let newAngle = (object.get('angle') + delta) % 360; // On divise par 360 car fabric ne reset pas la position à 0 quand on continue de tourner
          if (Math.abs(newAngle) < Math.abs(delta)) {
            // Si on est proche de l'angle 0, on se positionne dessus. ça permet de reset l'angle de manière précise.
            newAngle = 0;
          }

          object.set('angle', newAngle);
          this.checkIfOutsideFace();
          this.updateActionBarPosition();
        } else {
          // Déplacement de l'objet sélectionné

          let delta = shiftKey ? 20 : 2;
          if (keyCode === keyCodes.arrowLeft || keyCode === keyCodes.arrowTop) delta = -delta;

          const property = keyCode === keyCodes.arrowLeft || keyCode === keyCodes.arrowRight ? 'left' : 'top';
          object.set(property, object.get(property) + delta);
          this.guideObject(object, 1);
          this.checkIfOutsideFace();
          this.updateActionBarPosition();
          object.setCoords();
        }

        this.canvas.renderAll();
        // On positionne le code clavier afin de déclencher une mise à jour de la configuration sur le key up de cette touche
        this.updateObjectsOnKeyUp = keyCode;

        break;
        default:
          break;
    }
  };

  onKeyUp = (event) => {
    if (this.updateObjectsOnKeyUp === event.keyCode) {
      this.updateObjectsOnKeyUp = null;
      this.onObjectChange();
    }
  };

  /**
   * Focus le container afin de recevoir les événements clavier
   */
  focusContainer = (container) => {
    setTimeout(() => {
      container.focus();
    })
  };

  removeItemsFromCanvas = (props, items) => {
    const objectsId = _.map(items, 'id');
    // On fait en 2 étapes la suppression sinon il se passe un truc bizarre lorsqu'il y a plusieurs
    // objets Si on a un groupe à 2 éléments, le 2nd sera vide en faisant un forEach directement sur
    // getObjects()
    const objects = _.filter(this.canvas.getObjects(), obj => {
      return obj.id && objectsId.indexOf(obj.id) >= 0;
    });
    _.each(objects, obj => this.canvas.remove(obj));//obj.remove());
  };

  onRemoveObjects = () => {
    const accessories = this.props.configuration.accessories;
    const categ = this.props.configuration.monument.category;
    let bDo = true;

    if (categ ==='ACC'){
      if ( accessories.length > 1){
        bDo = true;
      }
    }
    if (bDo === true){
      const selected = this.state.selectedObjects;
        // On déselectionne sur le canvas (utile pour les groupes de sélection)
        this.unselectAll();
    
        this.doRemoveObjects(selected);
    }
  };
  onEditEngraving = () => {
    this.setState({editEngrav: true})
  }

  removeAllEngravings = () => {
    this.props.actions.removeAllEngravings();
  };

  doRemoveObjects = (objects) => {
    // GTM Obj
    const gtmObj = {
      category: "configurateur",
      type: "picto"
    }
    // end GTM Obj
    const objectsByType = _.groupBy(objects, 'type');

    if (objectsByType[typePattern]) {
      this.gtmEvents({
        ...gtmObj,
        name: "supprimer motif",
        subcategory: "motif",
        from: "configuration motif"
      }, { template: "configurateur", subtemplate: "liste motifs" })
      const patternIds = _.map(objectsByType[typePattern], 'id');
      this.props.actions.removePatterns(patternIds);
    }

    if (objectsByType[typeEngraving]) {
      this.gtmEvents({
        ...gtmObj,
        name: "supprimer gravure",
        subcategory: "gravure",
        from: "configuration gravure"
      }, { template: "configurateur", subtemplate: "liste gravures" })
      const engravingIds = _.map(objectsByType[typeEngraving], 'id');
      this.props.actions.removeEngravings(engravingIds);
    }

    if (objectsByType[typeAccessory]) {
      this.gtmEvents({
        ...gtmObj,
        name: "supprimer accessoire",
        subcategory: "accessoire",
        from: "configuration accessoire"
      }, { template: "configurateur", subtemplate: "liste accessoires" })
      const ids = _.map(objectsByType[typeAccessory], 'id');
      const piece = _.map(objectsByType[typeAccessory], 'reference');
      this.props.actions.removeAccessories(ids);
      if(!isQuote(this.props.configuration)){
        this.props.actions.removeEngravingsByPice(ids);
        this.props.actions.removePatternsByPiece(ids);
      }
    }
  };

  // GTM
  gtmEvents = (gtmObj, page) => {
    if (this.props.family) {
      import("../../../services/gtmFamily")
      .then(({ gtmFamily }) => {
        gtmFamily().ctaOfConfiguration(gtmObj, page, {}, "partner", "product", "user", "page")
      });
    }
  }
  // end GTM

  onDuplicateObject = () => {
    const object = this.state.selectedObjects[0];
    const x = object.left / this.canvas.width;
    const y = (object.top + object.height) / this.canvas.height;

    switch (object.type) {
      case typePattern:
        this.props.actions.duplicatePattern(object.id);
        break;
      case typeEngraving:
        this.props.actions.duplicateEngraving(object.id, x, y);
        break;
      case typeAccessory:
        this.props.actions.duplicateAccessory(object.id, x, y);
        break;
      default:
        break;
    }
  };

  unselectAll = () => {
    // this.canvas.deactivateAllWithDispatch();
    this.canvas.discardActiveObject();
  };

  onObjectScaling = () => {
    this.applyOnSelectedObjects(objects => {
      _.forEach(objects, obj => {
        obj.set({
          scaleX: Math.max(obj.minScale, Math.min(obj.scaleX, obj.maxScale)),
          scaleY: Math.max(obj.minScale, Math.min(obj.scaleY, obj.maxScale)),
        });
      });
    });
    this.checkIfOutsideFace();
    this.updateActionBarPosition();
  };

  onObjectMoving = (event) => {
    this.guideObject(event.target);
    this.checkIfOutsideFace();
    this.updateActionBarPosition();
  };

  onObjectRotating = () => {
    this.updateActionBarPosition();
  };

  onTextChanged = (event) => {
    const textObject = event.target;

    // orignalState correspond au moment du focus du textfield, il n'est pas mis à jour
    // à chaque frappe de caractère.

    const original = textObject._stateProperties;

    if (textObject.selectionStart > 0 && textObject.text[textObject.selectionStart - 1] === '\n') {
      // On vient de taper un saut de ligne, on split le text en 2 bloc
      const previousText = textObject.text.substring(0, textObject.selectionStart - 1);
      const newText = textObject.text.substring(textObject.selectionStart).trim()+" ";

      // Mise à jour du précédent élément
      textObject.set('text', previousText);
      this.repositionEngravingGivenNewWidth(textObject, original);
      this.onObjectChange(false);

      // Création de la nouvelle ligne (pas d'ajout dans le canvas, ce sera fait automatiquement lors de l'ajout dans la configuration)
      const newLineObject = fabric.util.object.clone(textObject);
      newLineObject.set('text', newText);
      this.repositionEngravingGivenNewWidth(newLineObject, original);
      newLineObject.set('top', textObject.top + textObject.height);
      newLineObject.selectAll();

      // Ajout de la ligne à la configuration
      this.props.actions.addNewLineForEngraving(
        textObject.id,
        newText,
        this.calculatePositionFromObject(newLineObject),
        this.calculateEngravingLines(newLineObject)
      );
    } else if (textObject.text.length > maxEngravingLength) {
      // Le texte dépasse la longueur maximum autorisée, on retire les caractères en trop et on repositionne le curseur
      const selectionStart = textObject.selectionStart;

      if (selectionStart >= textObject.text.length) {
        // Le curseur est positionné à la fin
        textObject.set('text', textObject.text.substring(0, maxEngravingLength));
        textObject.set('selectionStart', maxEngravingLength);
        textObject.set('selectionEnd', maxEngravingLength);
      } else {
        // Le composant a un comportement très étrange, si on tape plusieurs caractères au dessus de la limite,
        // on reçoit le text et selectionStart correspondant au texte tapé et ignorant le set('text')
        // on peut donc recevoir en premier le texte : xxxxAxxxx avec le curseur situé après le A pour une limite de 8.
        // on retire alors le A. Si on en tape un autre, on reçoit xxxxAAxxxx avec le curseur situé après le 2nd A
        // Mais la valeur sauvegardée est bonne!
        // On calcule donc un delta pour retirer tous les caractères en trop
        const delta = textObject.text.length - maxEngravingLength;

        // Texte avant le curseur et le nb de caractères en trop
        let newText = selectionStart - delta > 0
          ? textObject.text.substring(0, selectionStart - delta)
          : '';

        // On ajoute le texte situé après le curseur
        newText += textObject.text.substring(selectionStart, maxEngravingLength + delta);

        // On s'assure que le texte ne dépasse pas la limite de taille
        newText = newText.substring(0, maxEngravingLength);

        textObject.set('text', newText);
        textObject.set('selectionStart', Math.max(0, selectionStart - delta));
        textObject.set('selectionEnd', Math.max(0, selectionStart - delta));
      }

      this.repositionEngravingGivenNewWidth(textObject, original);
      this.onObjectChange(false);
    } else if (textObject.text.split("\n").length > 1) {
      const lines = textObject.text.split("\n")
      // Mettre à jour le premier élément
      textObject.set('text', lines[0]);
      this.repositionEngravingGivenNewWidth(textObject, original);
      this.onObjectChange(false);

      // Ajouter d'autres éléments (les lignes)
      lines.forEach((value, index) => {
        if (index < 1) return;
        let newLineObject = fabric.util.object.clone(textObject);
        const newText = value
        newLineObject.set('text', newText);
        this.repositionEngravingGivenNewWidth(newLineObject, original);
        textObject.height = 65 * index
        newLineObject.set('top', textObject.top + textObject.height)

        // Ajouter la ligne à configurer
        this.props.actions.addNewLineForEngraving(
          textObject.id,
          newText,
          this.calculatePositionFromObject(newLineObject),
          this.calculateEngravingLines(newLineObject)
        );
      })
    } else {
      this.repositionEngravingGivenNewWidth(textObject, original);
      this.onObjectChange(false);
      this.checkIfOutsideFace();
      this.updateActionBarPosition();
    }
  };

  onTextEditingExited = () => this.focusContainer(this.canvasContainer);

  /**
   * Quand on modifie le texte d'une gravure alignée à gauche ou à droite,
   * le comportement par défaut de fabric est de recentrer le bloc de texte
   * avec la nouvelle largeur, au lieu de prendre en compte l'alignement.
   * Donc on force le positionnement du bloc de texte en fonction de sa position
   * originale et du delta de largeurs des gravures.
   *
   * @param object Objet modifié
   * @param original Etat de l'objet avant le passage en mode édition
   */
  repositionEngravingGivenNewWidth(object, original) {
    if (object.textAlign === 'left') {
      object.set('left', original.left - original.width / 2 + object.width / 2)
    } else if (object.textAlign === 'right') {
      object.set('left', original.left + original.width / 2 - object.width / 2)
    }
    object.set('top', original.top - original.height / 2 + object.height / 2)
  }

  toggleButtonsNavigationStyle = (applyStyle) => {
    const buttons = document.getElementsByClassName('button-navigation');
    if (applyStyle) {
      for (let button of buttons) { button.classList.add('bottom50'); }
    } else {
      for (let button of buttons) { button.classList.remove('bottom50'); }
    }
  }

  onObjectSelectionCreated = (event) => {
    this.toggleButtonsNavigationStyle(true)
    const engravings = _.chain(this.canvas.getObjects())
      .filter(obj => obj.type === typeEngraving)
      .value();

    const objects = _.filter(event.target.getObjects ? event.target.getObjects() : [event.target],
      obj => {
        return obj.type === typePattern || obj.type === typeEngraving || obj.type === typeAccessory;
      });

    if (objects.length === 1 && engravings.length > 1) {
      engravings.forEach(engraving => {
        engraving.set(Object.assign({}, defaultOptions, { backgroundColor: 'transparent' }));
      });
    }

    if (objects.length === 1) {
      if (this.props.configuration.designType && this.props.configuration.designType === "B") {
        event.target.set(Object.assign({}, defaultOptions, {backgroundColor: 'rgba(0, 149, 232, 0.3)'}));
      }
      objects[0].bringToFront();
    } else {
      // Il s'agit d'un groupe, on lui assigne les options par défaut de couleur, opacité, etc...
      event.target.set(Object.assign({}, defaultOptions, {
        // Certains éléments ne peuvent pas être tournés. Dans ce cas, on masque le point de rotation
        hasRotatingPoint: _.every(objects, 'hasRotatingPoint'),
      }));
    }

    this.addAllGuides(event.target);
    this.updateSelection(objects);
  };

  onObjectSelectionCleared = () => {
    this.toggleButtonsNavigationStyle(false)
    this.removeAllGuides();
    // Si on déselectionne une gravure vide, on la supprime
    const {selectedObjects} = this.state;
    if (selectedObjects.length === 1 && this.props.configuration.designType && this.props.configuration.designType === "B") {
      selectedObjects[0].set(Object.assign({}, defaultOptions, {backgroundColor: 'rgba(0, 149, 232, 0)'}));
    }
    if (selectedObjects.length === 1 && selectedObjects[0].type === typeEngraving && !selectedObjects[0].text.trim()) {
      const engravingId = selectedObjects[0].id;
      setTimeout(() => {
        // On décale le traitement sinon ça fait des bugs bizarres (on ne peut plus éditer de texte par exemple)
        this.props.actions.removeEngravings([engravingId]);
      });
    } else {
      const objects = _.chain(this.canvas.getObjects())
        .filter(obj => obj.type === typePattern || obj.type === typeEngraving || obj.type === typeAccessory)
        .filter(obj => this.isObjectOutsideFace(obj, false))
        .value();
      if (objects.length) {
        this.doRemoveObjects(objects);
      }
    }

    this.checkIfOutsideFace();
    this.updateSelection([]);
  };

  onObjectChange = (updateState = true) => {
    // GTM
    const objectsByType = _.groupBy(this.state.selectedObjects, 'type');
    if (objectsByType[typeEngraving]) {
      this.gtmEvents({
        name: "modifier gravure",
        category: "configurateur",
        subcategory: "liste gravures",
        type: "picto",
        from: "configuration gravure"
      }, { template: "configurateur", subtemplate: "liste gravures" })
    } 
    else if(objectsByType[typeAccessory]) {
      this.gtmEvents({
        name: "modifier accessoire",
        category: "configurateur",
        subcategory: "liste granits accessoires",
        type: "picto",
        from: "configuration accessoire"
      }, { template: "configurateur", subtemplate: "liste accessoires" })
    }
    // end GTM
    this.applyOnSelectedObjects(objects => {
      const patterns = [];
      const engravings = [];
      const accessories = [];
      _.chain(objects)
        .filter(
          obj => obj.type === typePattern || obj.type === typeEngraving || obj.type === typeAccessory)
        .forEach(obj => {
          const position = this.calculatePositionFromObject(obj);

          switch (obj.type) {
            case typePattern:
              const pattern = _.find(this.props.configuration.patterns, {id: obj.id});
              if (!pattern) break;

              position['2d'] = position.fabricJS;

              patterns.push(Object.assign({}, pattern, {
                position: Object.assign({}, pattern.position, position)
              }));
              break;

            case typeEngraving:
              const engraving = _.find(this.props.configuration.engravings, {id: obj.id});
              if (!engraving) break;
              engravings.push(Object.assign({}, engraving, {
                text: obj.text,
                position: Object.assign({}, engraving.position, position),
                lines: this.calculateEngravingLines(obj),
              }));
              break;

            case typeAccessory:
              const accessory = _.find(this.props.configuration.accessories, {id: obj.id});
              if (!accessory) return;

              position['2d'] = position.fabricJS;

              accessories.push(Object.assign({}, accessory, {
                position: Object.assign({}, accessory.position, position)
              }));
              break;

            default:
              break;
          }
        })
        .value();
      
      if ((patterns.length || engravings.length || accessories.length)) {
        this.props.actions.updateFabricElements({
          patterns,
          engravings,
          accessories,
        }, updateState);
      }
    })
  };

  calculatePositionFromObject(obj) {
    const centerPoint = obj.getCenterPoint();
    return {
      fabricJS: {
        x: centerPoint.x / this.canvas.width,
        y: centerPoint.y / this.canvas.height,
      },
      size: {
        width: obj.width / this.canvas.width,
        height: obj.height / this.canvas.height,
      },
      scale: {
        x: obj.flipX ? -obj.scaleX : obj.scaleX,
        y: obj.scaleY,
      },
      rotation: obj.angle,
      scaleRatio:{x:obj.ratioX, y:obj.ratioY}
    };
  }

  applyOnSelectedObjects = (cb) => {
    // Récupération des objets modifié
    let objects = [];
     objects= this.canvas.getActiveObjects();


    //TODOCHECK
    // if (this.canvas.getActiveGroup()) {
    //   // Il s'agit d'un groupe d'objet
    //   group = this.canvas.getActiveGroup();
    //   objects = this.canvas.getActiveGroup().getObjects();
    //   // On restaure les objets du groupe pour récupérer les valeurs relatives au canvas et non au groupe
    //   group._restoreObjectsState();
    // } else if (this.canvas.getActiveObject()) {
    //   objects = [this.canvas.getActiveObject()];
    // } else {
    //   objects = [];
    // }

    cb(objects);

    // if (group) {
    //   // On reassocie les objets au groupe et on recalcule les coordonnées
    //   fabric.util.resetObjectTransform(group);
    //   // _.forEach(objects, object => {
    //   //   group._setObjectActive(object);
    //   // });
    //   group._calcBounds();
    //   group._updateObjectsCoords();
    //   this.updateActionBarPosition();
    // }
  };

  updateSelection = (selectedObjects) => {
    if (this.props.onSelectObjects) {
      this.props.onSelectObjects(selectedObjects);
    }

    this.setState(Object.assign({selectedObjects}, this.calculateActionBarPosition()));
  };

  updateActionBarPosition = () => {
    this.setState(this.calculateActionBarPosition());
  };

  calculateActionBarPosition = () => {
    const selectedObject = this.canvas &&
      (this.canvas.getActiveObject());
    if (selectedObject) {
      // Pour la position top, on veut le corner le plus bas. On test donc les 4 possibilités.
      return {
        actionsPositionX: (_.get(this.canvas, '_offset.left') || 0) +
        (selectedObject.getCenterPoint().x * this.canvas.getZoom()),
        // boundingRect ou oCoords ne se mettent pas à jour pendant moving/scaling/rotating
        // On utilise donc le getPointByOrigin
        actionsPositionY: (
          _.max([
            selectedObject.getPointByOrigin('left', 'top').y,
            selectedObject.getPointByOrigin('left', 'bottom').y,
            selectedObject.getPointByOrigin('right', 'top').y,
            selectedObject.getPointByOrigin('right', 'bottom').y,
          ]) + 5 // On ajoute un peu pour ne pas que les boutons collent à la bordure
        ) * this.canvas.getZoom(),
      }
    } else {
      return {
        actionsPositionX: -9999,
        actionsPositionY: -9999,
      };
    }
  };

  /**
   * Supprime tous les guides du canvas
   */
  removeAllGuides = () => {
    _.forEach(this.vGuides, guide => {
      this.canvas.remove(guide);
    });
    this.vGuides = [];

    _.forEach(this.hGuides, guide => {
      this.canvas.remove(guide);
    });
    this.hGuides = [];

    _.forEach(this.objectGuides, guide => {
      this.canvas.remove(guide);
    });
    this.objectGuides = null;

    this.canvas.renderAll();
  };

  /**
   * Crée un guide non visible et l'ajoute au canvas
   */
  createGuide = (x1, y1, x2, y2, color) => {
    const guide = new fabric.Line([x1, y1, x2, y2], {
      stroke: color,
      strokeWidth: 4,
      strokeDashArray: [8, 8],
      selectable: false,
      visible: false,
    });
    this.canvas.add(guide);
    return guide;
  };

  /**
   * Ajoute un guide vertical
   */
  addVGuide = (x, y1, y2, color) => {
    let createNewGuide = true;

    y1 -= guideExtension;
    y1 = Math.max(0, y1);
    y2 += guideExtension;
    y2 = Math.min(this.canvas.getHeight(), y2);
    for (let g = this.vGuides.length - 1; g >= 0; g--) {
      if (this.vGuides[g].x1 === x && this.vGuides[g].x2 === x) {
        this.vGuides[g].set({
          y1: Math.min(y1, this.vGuides[g].y1),
          y2: Math.max(y2, this.vGuides[g].y2)
        });
        createNewGuide = false;
      }
    }

    if (createNewGuide) {
      this.vGuides.push(this.createGuide(x, y1, x, y2, color));
    }
  };

  /**
   * Ajoute un guide horizontal
   */
  addHGuide = (y, x1, x2, color) => {
    let createNewGuide = true;

    x1 -= guideExtension;
    x1 = Math.max(0, x1);
    x2 += guideExtension;
    x2 = Math.min(this.canvas.getWidth(), x2);

    for (let g = this.hGuides.length - 1; g >= 0; g--) {
      if (this.hGuides[g].y1 === y && this.hGuides[g].y2 === y) {
        this.hGuides[g].set({
          x1: Math.min(x1, this.hGuides[g].x1),
          x2: Math.max(x2, this.hGuides[g].x2)
        });
        createNewGuide = false;
      }
    }

    if (createNewGuide) {
      this.hGuides.push(this.createGuide(x1, y, x2, y, color));
    }
  };

  /**
   * Ajoute tous les guides pour l'objet sélectionné
   */
  addAllGuides = (target) => {
    this.removeAllGuides();

    const addGuides = (obj, onlyCenter) => {
      if(obj){
        const top = obj.top - obj.height / 2;
        const right = obj.left + obj.width / 2;
        const bottom = obj.top + obj.height / 2;
        const left = obj.left - obj.width / 2;
  
        this.addVGuide(obj.left, top, bottom, 'red');
        this.addHGuide(obj.top, left, right, 'red');
  
        if (!onlyCenter) {
          this.addVGuide(left, top, bottom, 'blue');
          this.addVGuide(right, top, bottom, 'blue');
  
          this.addHGuide(top, left, right, 'blue');
          this.addHGuide(bottom, left, right, 'blue');
        }
      }
    };

    // On initialise les guides de tous les objets autre que celui sélectionné
    _.forEach(this.canvas.getObjects(), obj => {
      if (obj === target || (target.contains && target.contains(obj))) return;
      if ([typeAccessory, typeEngraving, typePattern].indexOf(obj.type) < 0) return;

      addGuides(obj, false);
    });

    // Init des guides pour le canvas/face
    addGuides(this.faceBoundingRect, true);

    // Init des guides pour l'objet sélectionné
    // Les positions sont mises à zéro, elles seront indiqués lors du déplacement de l'objet
    this.objectGuides = {};
    _.forEach(['hTop', 'hCenter', 'hBottom', 'vLeft', 'vCenter', 'vRight'], prop => {
      this.objectGuides[prop] =
        this.createGuide(0, 0, 0, 0, prop === 'hCenter' || prop === 'vCenter' ? 'red' : 'blue');
    });

    this.canvas.renderAll();
  };

  /**
   * Affiche les guides pour un objet selon sa position courante
   * @param target L'objet à guider
   * @param snapDistanceInPx Delta autorisé pour afficher le guide et pour forcer la position de l'objet afin d'avoir un effet de lock
   */
  guideObject = (target, snapDistanceInPx = defaultSnapDistanceInPx) => {
    // On cache tous les guides de l'objet
    _.forEach(this.objectGuides, guide => guide.set({visible: false}));

    //### Guides verticaux
    let absSnappedLeftInPx = target.left;
    let isVSnapped = false;

    // Pour chaque ligne verticale de l'objet, on associe son offset par rapport au centre et le guide à
    // afficher
    const targetVSnapOffsets = [
      {
        offset: -target.getScaledWidth() / 2,
        guide: this.objectGuides.vLeft,
      }, {
        offset: target.getScaledWidth() / 2,
        guide: this.objectGuides.vRight,
      }, {
        offset: 0,
        guide: this.objectGuides.vCenter,
      }
    ];

    // Pour chaque guide vertical, on regarde si sa position x correspond à l'un des x de l'objet
    _.forEach(this.vGuides, vGuide => {
      vGuide.set('visible', false);

      _.forEach(targetVSnapOffsets, ({offset, guide}) => {
        const x = target.left + offset;

        if ((x - snapDistanceInPx) < vGuide.left && vGuide.left < (x + snapDistanceInPx)) {
          vGuide.set('visible', true);
          vGuide.bringForward();
          absSnappedLeftInPx = vGuide.left - offset;

          guide.set({
            x1: vGuide.x1,
            y1: target.top - target.getScaledHeight() / 2 - guideExtension,
            x2: vGuide.x2,
            y2: target.top + target.getScaledHeight() / 2 + guideExtension,
            visible: true,
          });
          this.canvas.bringForward(guide);

          isVSnapped = true;
        }
      });
    });

    // On déplace l'objet sur le même axe vertical
    if (!isVSnapped) {
      const leftInPx = (target.left - target.getScaledWidth() / 2) -
        (this.faceBoundingRect.left - target.getScaledWidth() / 2);
      const snappedLeftInMm = Math.round(leftInPx / gridInMm) * gridInMm;
      absSnappedLeftInPx =
        (this.faceBoundingRect.left - target.getScaledWidth() / 2) + (snappedLeftInMm + target.getScaledWidth() / 2);
    }

    //### Guides horizontaux
    let absSnappedTopInPx = target.top;
    let isHSnapped = false;

    // Pour chaque ligne horizontal de l'objet, on associe son offset par rapport au centre et le guide
    // à afficher
    const targetHSnapOffsets = [
      {
        offset: -target.getScaledHeight() / 2,
        guide: this.objectGuides.hTop,
      }, {
        offset: target.getScaledHeight() / 2,
        guide: this.objectGuides.hBottom,
      }, {
        offset: 0,
        guide: this.objectGuides.hCenter,
      }
    ];

    // Pour chaque guide horizontal, on regarde si sa position y correspond à l'un des y de l'objet
    _.forEach(this.hGuides, hGuide => {
      hGuide.set('visible', false);

      targetHSnapOffsets.forEach(({offset, guide}) => {
        const y = target.top + offset;

        if ((y - snapDistanceInPx) < hGuide.top && hGuide.top < (y + snapDistanceInPx)) {
          hGuide.set('visible', true);
          hGuide.bringForward();
          absSnappedTopInPx = hGuide.top - offset;

          guide.set({
            x1: target.left - target.getScaledWidth() / 2 - guideExtension,
            y1: hGuide.y1,
            x2: target.left + target.getScaledWidth() / 2 + guideExtension,
            y2: hGuide.y2,
            visible: true,
          });
          this.canvas.bringForward(guide);

          isHSnapped = true;
        }
      });
    });

    // On déplace l'objet sur le même axe horizontal
    if (!isHSnapped) {
      const bottomInPx = (this.faceBoundingRect.top + this.faceBoundingRect.height / 2) -
        (target.top + target.getScaledHeight() / 2);
      const snappedBottomInPx = Math.round(bottomInPx / gridInMm) * gridInMm;
      absSnappedTopInPx =
        (this.faceBoundingRect.top + this.faceBoundingRect.height / 2) -
        (snappedBottomInPx + target.getScaledHeight() / 2);
    }

    target.set({
      left: absSnappedLeftInPx,
      top: absSnappedTopInPx
    });
  };

  /**
   * @param obj L'objet à tester
   * @param onlyCenter true si l'on considère que l'objet est en dehors si son centre l'est,
   *  false s'il doit être entierement dehors
   */
  isObjectOutsideFace = (obj, onlyCenter) => {
    if (!this.canvas || !this.faceBoundingRect) {
      return false;
    }

    const leftBorder = this.faceBoundingRect.left - this.faceBoundingRect.width / 2;
    const rightBorder = this.faceBoundingRect.left + this.faceBoundingRect.width / 2;
    const topBorder = this.faceBoundingRect.top - this.faceBoundingRect.height / 2;
    const bottomBorder = this.faceBoundingRect.top + this.faceBoundingRect.height / 2;

    if (onlyCenter) {
      const center = obj.getCenterPoint();
      return center.x < leftBorder ||
        center.x > rightBorder ||
        center.y < topBorder ||
        center.y > bottomBorder;
    } else {
      return getCoordinate(_.min, 'x') > rightBorder ||
        getCoordinate(_.max, 'x') < leftBorder ||
        getCoordinate(_.min, 'y') > bottomBorder ||
        getCoordinate(_.max, 'y') < topBorder;
    }
    function getCoordinate(fn, prop) {
      return fn([
        obj.getPointByOrigin('left', 'top')[prop],
        obj.getPointByOrigin('left', 'bottom')[prop],
        obj.getPointByOrigin('right', 'top')[prop],
        obj.getPointByOrigin('right', 'bottom')[prop],
      ])
    }
  };

  checkIfOutsideFace = () => {
    if (!this.canvas) {
      return;
    }

    let visible = false;
    if (this.canvas.getActiveObject()) {
      this.applyOnSelectedObjects((objects) => {
        visible = _.chain(_.unionBy(objects, this.canvas.getObjects(), 'id'))
          .filter(({type}) => type === typeEngraving || type === typeAccessory || type === typePattern)
          .some(obj => this.isObjectOutsideFace(obj, true))
          .value();
      });
    } else {
      visible = _.chain(this.canvas.getObjects())
        .filter(({type}) => type === typeEngraving || type === typeAccessory || type === typePattern)
        .some(obj => this.isObjectOutsideFace(obj, true))
        .value();
    }
    this.setOutsideFaceBoxesVisibility(visible);
  };

  setOutsideFaceBoxesVisibility = (visible) => {
    _.forEach(this.canvas.getObjects(), obj => {
      if (obj.type === outsideFaceBoxType) {
        obj.set({
          visible
        });
      }
    });
    this.setState({
      movingOutside: visible,
    });
  };

  restrictObjectMovement = (e) => {
    const obj = e.target
    const boundingRect = this.faceBoundingRect
  
    const leftBorder = boundingRect.left - boundingRect.width / 2
    const rightBorder = boundingRect.left + boundingRect.width / 2
    const topBorder = boundingRect.top - boundingRect.height / 2
    const bottomBorder = boundingRect.top + boundingRect.height / 2

    if (obj.left < leftBorder) {
      obj.left = leftBorder
    }
    if (obj.left + 1 > rightBorder) {
      obj.left = rightBorder - 1
    }
    if (obj.top < topBorder) {
      obj.top = topBorder
    }
    if (obj.top + 1 > bottomBorder) {
      obj.top = bottomBorder - 1
    }
  };

  render() {
    const t = this.context;
    const containerStyle = this.state.movingOutside ? {backgroundColor: outsideFaceColor} : {};
    let leftValue = !this.state.actionPositionX && this.state.actionsPositionX < 0 ? 50 : this.state.actionsPositionX;
    let topValue = !this.state.actionPositionY && this.state.actionsPositionY < 0 ? 20 : this.state.actionsPositionY;
    return (
      <div className={this.props.className}>
        <figure ref={ref => this.canvasParent = ref} className="Fabric" style={containerStyle}>
          <div ref={ref => this.canvasContainer = ref} className="CanvasContainer"
               tabIndex={1000} style={{outline: 'none'}}
               onKeyDown={this.onKeyDown} onKeyUp={this.onKeyUp}
          >
            <canvas id="canvas" className="Canvas" width="1600" height="1200" />
          </div>
        </figure>
  
        {this.renderSpecificComponents()}
  
        <FabricActionBar
          left={leftValue} top={topValue} family={this.props.family}
          configuration={this.props.configuration} selectedObjects={this.state.selectedObjects}
          onRemoveObjects={this.onRemoveObjects} onDuplicateObject={this.onDuplicateObject} onEditEngraving={this.onEditEngraving}
          {...this.actionBarProps()}
          context={t}
        />
  
        {!this.props.family && <FabricFooter
          configuration={this.props.configuration}
          selectedObjects={this.state.selectedObjects} onRemoveObjects={this.onRemoveObjects}
          onDuplicateObject={this.onDuplicateObject}
          {...this.footerProps()}
        />}
      </div>
    );
  }
}
