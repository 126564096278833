import {get, post} from './api.service';

import {generateEngravingImage} from './generateEngravings.service';
import {checkConfigHideFlowerImg, checkHideAccessoryFlower,checkOrganizationHideFlowers} from './flower.service';
import {getReactAppExtranet} from './utils.service';
import {userCan} from '../services/userRights.service';

const host = process.env.REACT_APP_CONFIG3D_ORIGIN || "http://localhost:3010";
const media = process.env.REACT_APP_MEDIA_ORIGIN || "http://localhost:3040";

const configMonumentIsoOrTop = (type, size) =>
  ({monument, frame, headstoneRef, quoteId, reference, veneer=""}, {drawing, excludeDefaultPattern,forceHideFlowers=false}) => {
    if (!!drawing) drawing = 'drawing';
    const ref = headstoneRef ? monument.footstone.reference + headstoneRef : monument.reference,
      style = drawing || monument.graniteMain,
      frameStyle = frame && frame.mode ? ('-' + (drawing || frame.granite || monument.graniteMain)) : '',
      veneerStyle = veneer ? ((frameStyle) ? '-' : '--' + (drawing || veneer.granite || monument.graniteMain)) : '',
      secondaryGranite = (frameStyle ? veneerStyle ? "-" : "--" : veneerStyle ? "-" : "---")  + monument.graniteSecondary,
      filename = ref + '.jpg';

    let params = excludeDefaultPattern ? '?excludeDefaultPattern=true' : '';
    const hideFlowers = forceHideFlowers ? true: checkConfigHideFlowerImg();
    params = hideFlowers ? params ? params+"&hideFlowers=true": "?hideFlowers=true" : params;
    params += params ? "&layout="+monument.layout : "?layout="+monument.layout

    const veneerConfig  = veneer ? `&veneer=${JSON.stringify(veneer)}` : '';
    const kindConfig  = frame?.kind ? `&kind=${frame.kind}` : "";
    // On ne met pas de framestyle dans le filename pour que l'url de l'image soit la même que celle en
    // cache venue du catalogue.    
    if (quoteId){
      return getReactAppExtranet(`/quote_variants/${reference}/picture/${size}?granite=${style}`)     
    }else{
      return `${host}/view/monument/${type}/${ref}/${style}${frameStyle}${veneerStyle}${secondaryGranite}/${size}/${filename}${params}${veneerConfig}${kindConfig}`;
    }
    
  };

const accessory = (type, size, extension) =>
  (accessoryRef, graniteRef) =>{ 
    const hideFlowers = checkHideAccessoryFlower(accessoryRef);
    const params = hideFlowers && type !== "top" ? '?hideFlowers=true' : '' ;
   return ( `${host}/view/accessory/${type}/${accessoryRef}/${graniteRef}/${size}/` +
    `${accessoryRef}${graniteRef}.${extension}${params}`);
  }
;

const monumentIso = (size) => (monument) => {

  if (monument.image){
    return  monument.image
  }
  const ref = monument.reference;
  const granite = monument.graniteMain.reference;
  const params = checkOrganizationHideFlowers() ? '?hideFlowers=true' : '' ;
  return `${host}/view/monument/iso/${ref}/${granite}-${granite}/${size}/${ref}.jpg${params}`;

};

const monument3d = (size) => (monument) => {
  const ref = monument.reference,
    granite = monument.graniteMain.reference;
  return get(`${host}/view/monument/3d/${ref}/${granite}-${granite}/${size}`);
};

const configurationIso = (size) => (configuration,fullConfig=false) => {
  let params = checkConfigHideFlowerImg(fullConfig) ? '?hideFlowers=true' : '' ; 
  return `/view/configuration/iso/${configuration.reference}/${size}/${configuration.reference}.json${params}`;
};

const configurationIsoByGranit = (size) => (granite) => {
  return get(`/view/configuration/cache/all/large/${granite}/all.json`);
};

const configurationIsoWithGranites = (size) => (refConfig) => {
  return get(`/view/configuration/cache/${refConfig}/${size}/${refConfig}.json`);
};

const configuration3d = (configuration, image=false)=>{
  const hideFlowers = checkConfigHideFlowerImg();
  const params = image !== false ? hideFlowers ? `?mode=4k&image=${image}&hideFlowers=true`: `?mode=4k&image=${image}`:
                                   hideFlowers ? '?hideFlowers=true' : '';
  return post(`/api/images/configuration/3d${params}`, configuration);

}
// `${host}/view/frame/iso/${frameRef}/${graniteRef}/${size}/${frameRef}-${graniteRef}.jpg`;
// const frame = (type, size) =>
//   (frameRef, graniteRef, paramLayout, FrameLayout) =>
//   `${host}/view/frame/iso/${frameRef}/${graniteRef}/${size}/${frameRef}-${graniteRef}-${paramLayout}-${FrameLayout}.jpg`;

// const headstone = (type, size) =>
//   (headstoneRef, graniteRef) =>
//     `${host}/view/headstone/iso/${headstoneRef}/${graniteRef}/${size}/${headstoneRef}-${graniteRef}.jpg`;

// const veneerFaces = ['front', 'back', 'left', 'right'];
// const veneer = (type, size) =>
//   (veneer, background = '#FFFFFF') => {
//     const {dimensions = {}, granite} = veneer;
//     const double = veneerFaces.map(face => dimensions[face] && dimensions[face].length > 230 ? '1' : '0').join('-');
//     const active = veneerFaces
//       .map(face => {
//         const {length, thickness, front, back, left, right} = (dimensions[face] || {});
//         return !!length && !!thickness && (!!front || !!back || !!left || !!right) ? '1' : '0'
//       })
//       .join('-');
//     return (
//       `${host}/view/veneer/iso/${double}/${active}/${granite}/${encodeURIComponent(background)}/${size}/veneer-${double}-${active}-${granite}-${size}.jpg`
//     );
//   };

export const imgs = {
  monument: {
    iso: {
      large: monumentIso('large'),
      small: monumentIso('small'),
      thumbnail: monumentIso('thumbnail')
    },
    '3d': {
      small: monument3d('small'),
      large: monument3d('large'),
    }
  },
  granite: {
    // large: (granite) => `${media}/optimized/granits/${granite.reference}.jpg`,
    // small: (granite) => `${media}/optimized/granits/${granite.reference}.jpg`,
    thumbnail: (granite) => `${media}/granits/resized/${getGraniteWithVariant(granite)}.jpg`,
  },
  configuration: {
    monument: {
      iso: {
        large: configMonumentIsoOrTop('iso', 'large'),
        small: configMonumentIsoOrTop('iso', 'small'),
        thumbnail: configMonumentIsoOrTop('iso', 'thumbnail'),
      },
      top: {
        large: configMonumentIsoOrTop('top', 'large'),
        small: configMonumentIsoOrTop('top', 'small'),
        thumbnail: configMonumentIsoOrTop('top', 'thumbnail'),
      },
    },
    // 'frame_head_front': (configuration,faces )=>{
    //   const foundFace = faces.find(face=>face.profile.reference === "Avant"); // On cherche la face Avant
    //   if(foundFace){
    //     return post(`/api/images/configuration/face/${foundFace.piece.reference}/Avant`, configuration);
    //   }
    //   else return null;
      
    // },
    iso: {
      large: configurationIso('large'),
      small: configurationIso('small'),
      thumbnail: configurationIso('thumbnail'),
      large_granite: configurationIsoByGranit('large'),
      large_with_granites: configurationIsoWithGranites('large'),
    },
    '3d': (configuration) => configuration3d( configuration),
    '3d-image': (configuration,image) => configuration3d( configuration,image)
  },

  engravings: (engraving,user) => generateEngravingImage(engraving, 200,user),

  // face: ({monument, frame, face, size = 'thumbnail', drawing}) => {

  //   const granite = drawing ? 'drawing' : getGraniteCode(monument, undefined, frame),
  //     monumentRef = monument.reference,
  //     pieceRef = face.piece.reference,
  //     profileRef = face.profile.reference,
  //     fileName = [monumentRef, granite, pieceRef, profileRef].join('-');
  //   return `${host}/view/monument/face/${monumentRef}/${granite}/${pieceRef}` +
  //     `/${profileRef}/${size}/${fileName}.jpg`
  // },
  pattern: (pattern, type = 'drawing', size = 'thumbnail') => {
    return `${host}/view/pattern/${pattern}/${type}/${size}/${pattern}-${type}-${size}.png`
  },

  // frame: {
  //   iso: {
  //     large: frame('iso', 'large'),
  //     small: frame('iso', 'small'),
  //     thumbnail: frame('iso', 'thumbnail'),
  //   },
  //   config:(configId,frame, size, granite, frameparam, framlayout)=> {return `${host}/view/frame/${configId}/${frame}/${granite}/${size}/frame-${frameparam}-${framlayout}.jpg`}
  // },

  // veneer: {
  //   iso: {
  //     large: veneer('iso', 'large'),
  //     small: veneer('iso', 'small'),
  //     thumbnail: veneer('iso', 'thumbnail'),
  //   },
  //   config:(configId, size, granite, disposition, mesure)=> {return `${host}/view/veneer/${configId}/${size}/veneer-${granite}-${disposition}-${mesure}.jpg`}
  // },

  // accessory: {
  //   iso: {
  //     large: accessory('iso', 'large', 'jpg'),
  //     small: accessory('iso', 'small', 'jpg'),
  //     thumbnail: accessory('iso', 'thumbnail', 'jpg'),
  //   },
  //   top: {
  //     large: accessory('top', 'large', 'png'),
  //     small: accessory('top', 'small', 'png'),
  //     thumbnail: accessory('top', 'thumbnail', 'png'),
  //   },
  // },

  // headstone: {
  //   iso: {
  //     large: headstone('iso', 'large'),
  //     small: headstone('iso', 'small'),
  //     thumbnail: headstone('iso', 'thumbnail'),
  //   },
  // },
};

// function getGraniteCode(monument, granite, frame) {
//   granite = granite || monument.graniteMain;
//   frame = (frame && frame.mode) ? (frame.granite || monument.graniteMain) : null;
//   return granite + (frame ? '-' + frame : '');
// }

function getGraniteWithVariant(granite) {
  if (granite.variant && granite.variant !== 0) {
    return `${granite.reference}_${granite.variant}`
  }
  return granite.reference;
}
